:root {
    --backcolor: #fcd5ce;
    --bordercolor: #000;
    --borderwidth: 10px;
    --borderwidth2: 7px;
    --textcolor: #000;
    --flexgrow: 390px;
    --h2size: 25px;
    --h3size: 20px;
    --h5size: 15px;
    --logosize: 0px;
    --bcurl: null;
    --fontfam: "Major Mono Display";
    --backgroundatt: "scroll";
    --backdropblur: null;
}
@media only screen and (max-width: 1000px) {
    body {
        background-color: lightblue;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    div {
        color: var(--textcolor);
    }
    h2 {
        font-size: calc(var(--h2size) - 10px);
    }
    h3 {
        font-size: var(--h3size);
    }
    h5 {
        font-size: var(--h5size);
    }
    .backbtn {
        left: 12%;
    }

    .foodtypelist {
        border: var(--bordercolor) calc(var(--borderwidth) - 10px) solid;
        border-radius: 5px;
        /* padding: 5px; */
    }
    .colmob {
        flex-direction: column !important;
    }
    .rowmob {
        flex-direction: row !important;
    }
    .notmob {
        height: 0;
    }
    .avatarmain {
        width: 50px;

        right: 10px;
        top: 20px;
    }
    .logoholder {
        height: 60px;
    }
    .topmarginmob {
        margin-top: 20px !important ;
    }
    .comp {
        height: 50vh;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        flex-wrap: wrap;
        margin-top: 3vh;
    }
    .comp h2 {
        display: block;
        margin: 0 20px 0 20px;
        margin-bottom: 3vh;
    }
    .biggerpicmob {
        max-width: 50vw !important;
        max-height: 20vh !important;
    }
    .comp img {
        max-width: 25vw;
        max-height: 12vh;
        flex: 1 0 10%;
    }
    .row {
        margin-top: -40px;
    }
    .foodpairlistt {
        font-size: 10px;
    }
    .imgfinalbox {
        height: 5vh;
        width: 60vw;

        /* background-color: blue; */
    }

    .itemcount {
        position: absolute;
        top: 14%;
        left: 85%;
        transform: translateX(-50%);
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        height: 30px;
    }
    .flexgrow div {
        flex-basis: 30%;
    }
    .box400px {
        width: 200px;
        height: 200px;
    }
    .loader {
        margin-top: 1vh;
    }
}
