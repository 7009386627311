.ccountry_form {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
    height: fit-content;
}

.ccountry_form * {
    margin: 15px;
    height: 20px;
    width: 300px;
}
