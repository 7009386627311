:root {
    --backcolor: #fcd5ce;
    --bordercolor: #000;
    --borderwidth: 10px;
    --borderwidth2: 7px;
    --textcolor: #000;
    --flexgrow: 390px;
    --h2size: 25px;
    --h3size: 20px;
    --h5size: 15px;
    --logosize: 0px;
    --bcurl: null;
    --fontfam: "Major Mono Display";
    --backgroundatt: "scroll";
    --backdropblur: null;
}
@import url("https://fonts.googleapis.com/css2?family=Major+Mono+Display&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bungee+Shade&family=Cutive+Mono&family=Dancing+Script&family=Lobster+Two&family=Monoton&family=Orbitron&family=Press+Start+2P&family=Roboto:wght@100&family=Six+Caps&family=Staatliches&display=swap");

* {
    margin: 0%;
    font-family: var(--fontfam), monospace;
}
body {
    background-color: var(--backcolor);
    background-image: var(--bcurl);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: var(--backgroundatt);
    backdrop-filter: var(--backdropblur);
    height: 100vh;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
div {
    color: var(--textcolor);
}
h2 {
    font-size: var(--h2size);
}
h3 {
    font-size: var(--h3size);
}
h5 {
    font-size: var(--h5size);
}
.imgfinalbox img {
    min-width: 100%;
    /* min-height: 100%; */
}
.imgfinalbox {
    height: 15vh;
    width: 15vw;

    /* background-color: blue; */
}
.hrc,
hr {
    /* margin-left: 500px; */
    /* margin-bottom: 500px; */
    margin-right: 0;
    width: 100vw;
    border: #c2846b 5px double;
}
.desrows div div {
    margin-bottom: 20px;
}
.desrows div div h3 {
    margin-bottom: 15px;
}
.desrows div {
    margin-left: 40px;
    margin-right: 40px;
}
.row {
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: row !important;
}
.col {
    flex-direction: column;
}

.fullwidth {
    width: 100vw;
}
.box400px {
    width: 400px;
    height: 400px;
}
.emptydiv {
    height: 70px;
}
.emptydiv2 {
    height: 30px;
}
.emptydiv3 {
    height: 15px;
}
.itemcount {
    position: fixed;
    top: 20%;
    left: 8%;
    transform: translateX(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
}
.itemcount h4 {
    margin: 0;
    width: 120px;
}
.itemcount img {
    height: 100%;
}

.foodtypegrid {
    width: 100vw;
    display: flex;
    justify-content: space-evenly;

    align-items: center;
    flex-wrap: wrap;
}
.foodtypelist {
    border: var(--bordercolor) var(--borderwidth) solid;
    border-radius: 5px;
    /* padding: 5px; */
}
.thumnail {
    height: 100px;
}
.finalr {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.mainedit {
    /* position: -webkit-sticky; */
    /* position: sticky; */
    position: fixed;
    top: 300px;
    right: 20px;
}
.positionre {
    /* position: relative; */
    background-color: #ff0000;
    transform: none;
}
.sticky {
    position: fixed;
    top: 10px;
    left: 10px;
}
.edititem {
    width: 70vw;
}
.backbtn {
    position: fixed;
    top: 5%;
    left: 8%;
    cursor: pointer;
    height: 7%;
    transform: translateX(-50%);
}
.testdb {
    position: absolute;
    top: 40%;
    left: 50%;
    background-color: brown;
    width: fit-content;
    height: fit-content;
}
.App {
    text-align: center;
}

.comp {
    margin-top: 10vh;
    text-align: center;
}
.comp h2 {
    margin-bottom: 5vh;
}
.comp img {
    max-width: 10vw;
    max-height: 25vh;
    margin-left: 2%;
    margin-right: 2%;
}
.comp h3 {
    width: 60%;
    display: inline-block;
}

.avatarmain {
    width: 120px;
    position: absolute;
    right: 100px;
    top: 50px;
}
.loader {
    margin-top: 10vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.wineglass {
    height: 36px;
    position: relative;
}
.wineglass:after {
    content: "";
    position: absolute;
    top: 47px;
    left: 5px;
    width: 20px;
    height: 5px;
    background: white;
    box-shadow: 0 0 1px white;
}
.top {
    background: white;
    width: 30px;
    height: 36px;
    border-radius: 0 0 36px 36px;
    box-shadow: 0 0 1px white;
}
.top:before {
    content: "";
    position: absolute;
    left: 4px;
    bottom: 4px;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    background: linear-gradient(transparent 50%, #f36e3e 50%);
}
.left .top:before {
    animation: rotate2 2s linear infinite;
}
.right .top:before {
    animation: rotate1 2s linear infinite;
}
.top:after {
    content: "";
    position: absolute;
    top: 35px;
    left: 12px;
    width: 6px;
    height: 13px;
    background: white;
    box-shadow: 0 0 1px white;
}
.left {
    display: inline-block;
    margin-right: 10px;
    animation: rotate1 2s cubic-bezier(0.39, 1.52, 0.46, 0.92) infinite;
}
.right {
    display: inline-block;
    animation: rotate2 2s cubic-bezier(0.39, 1.52, 0.46, 0.92) infinite;
}
@keyframes rotate1 {
    0% {
        transform: rotate(0deg);
    }
    50% {
        transform: rotate(22deg);
    }
}
@keyframes rotate2 {
    0% {
        transform: rotate(0deg);
    }
    50% {
        transform: rotate(-22deg);
    }
}
ul {
    list-style: none;
    margin: 0;
    padding: 0;
    width: 70px;
    height: 25px;
    position: relative;
    opacity: 0;
    animation: 2s fadeInUp infinite linear 2s;
}
li {
    width: 6px;
    height: 15px;
    background: white;
    position: absolute;
    box-shadow: 0 0 1px white;
    transform-origin: bottom;
}
li:nth-child(1) {
    left: 26px;
    bottom: 5px;
    transform: rotate(-35deg);
}
li:nth-child(2) {
    left: 34px;
    bottom: 8px;
}
li:nth-child(3) {
    left: 42px;
    bottom: 5px;
    transform: rotate(35deg);
}
@keyframes fadeInUp {
    0% {
        opacity: 0;
        transform: scaleY(1);
    }

    33% {
        opacity: 1;
        transform: scaleY(1.4);
    }
    64% {
        opacity: 0.1;
        transform: scaleY(1);
    }
    100% {
        opacity: 0;
        transform: scaleY(0.3);
    }
}

.basiclist {
    flex-basis: var(--flexgrow);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 30px;

    height: auto;
    /* cursor: pointer; */
    cursor: url("https://i.imgur.com/5x78s1R.png"), auto;
}

.point {
    cursor: url("https://i.imgur.com/5x78s1R.png"), auto;
}
.typetext {
    /* width: 500px; */
    white-space: nowrap;
}

.border {
    border: var(--bordercolor) var(--borderwidth2) solid;
    padding: 7px;
}
.flexgrow div {
    flex-basis: var(--flexgrow);
}

.logo {
    height: 100%;
}
.logoholder {
    height: var(--logosize);
}
